import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import "./Hero.scss"

const Hero = ({ children, backgroundColor, backgroundImage }) => (
  <section className="flex-wrapper">
    <div className="backdrop flex-wrapper" style={{ backgroundColor }}>
      <Img
        fluid={backgroundImage}
        alt="Background image"
        style={{ minWidth: "100%" }}
      />
    </div>
    <div className="container">{children}</div>
  </section>
)

Hero.propTypes = {
  children: PropTypes.node,
}

export default Hero
