import React from "react"
import "./Header.scss"

const Header = ({ siteTitle }) => (
  <header id="header">
    <h1>Våra evenemang</h1>
  </header>
)

export default Header
